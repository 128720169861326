import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageTitle from '../../components/page_title'
import PageContents from '../../components/page_contents'
import * as style from '../../styles/blog.module.css'

export default function BlogPost({ data }) {
  const { title, author, date } = data.markdownRemark.frontmatter

  return (
    <Layout showFooter={true}>
      <Seo
        title={title}
        description={`${title} - blog post by ${author} from ${date}.`}
        isBlogPost={true}
      />

      <PageContents>
        <PageTitle>What We're Thinking</PageTitle>

        <div className={`${style.post_main} wide_content`}>
          <article className={style.post_contents}>
            <div className={style.post_title}>{title}</div>

            <div className={style.post_author}>
              by {author} - {date}
            </div>

            <div
              className={style.post_text}
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </article>

          <aside className={style.recent_posts}>
            <span className={style.recent_posts_headline}>
              Recent Blog Posts
            </span>

            {data.allMarkdownRemark.nodes.map(post => (
              <a
                key={post.fields.slug}
                href={`/blog${post.fields.slug}`}
                className={style.recent_post_link}
              >
                {post.frontmatter.title}
              </a>
            ))}

            <Link
              to={'/blog/all'}
              className={style.post_link}
              style={{ alignSelf: 'center', marginTop: '0.5rem' }}
            >
              View All
            </Link>
          </aside>
        </div>
      </PageContents>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      nodes {
        frontmatter {
          title
        }
        fields {
          slug
        }
      }
    }
  }
`
